import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carouselBlocks = document.querySelectorAll('.news-carousel-block');

  if (0 < carouselBlocks.length) {
    carouselBlocks.forEach((carouselBlock) => {
      new Swiper(
        carouselBlock.querySelector('.news-carousel-block__carousel'),
        {
          slidesPerView: 1.2,
          slidesPerGroup: 1,
          spaceBetween: 10,
          loop: true,
          navigation: {
            prevEl: carouselBlock.querySelector('.btn-prev'),
            nextEl: carouselBlock.querySelector('.btn-next'),
          },
          a11y: {
            nextSlideMessage: 'Prochain panneau',
            prevSlideMessage: 'Panneau précédent',
          },

          breakpoints: {
            768: {
              slidesPerView: 1.7,
              centeredSlides: false,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 3.5,
              spaceBetween: 30,
              centeredSlides: true,
            },
          },
        }
      );
    });
  }
});
